import React from 'react';

const Format = ({ className = '' }) => {
  return (
    <svg className={className} width="74" height="74" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path fill="#F9FAFA" d="M-683-216H757V896H-683z" />
        <g>
          <circle fillOpacity=".09473339" fill="#5C48D3" cx="37" cy="37" r="37" />
          <path
            d="M36.5131576 52.5789477c6.7218868 0 12.1710527-1.743733 12.1710527-3.8947368 0-1.0755019-1.3622915-2.0491861-3.5648188-2.7539948-2.2025273-.7048088-5.2452905-1.140742-8.6062339-1.140742-1.3055505 0-2.56309036.0657786-3.74201998.1875441-4.89102411.5051683-8.42903262 1.9739651-8.42903262 3.7071927 0 2.1510038 5.44916587 3.8947368 12.1710526 3.8947368z"
            stroke="#5C48D3"
            strokeWidth="1.25"
          />
          <path fill="#EAE9F6" d="M37.0000003 48.6842102l-3.8947369-4.8684211h7.78947371z" />
          <g
            transform="translate(26.289473 21.421053)"
            stroke="#5C48D3"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.25"
          >
            <path d="M16.1534211 19.8960468c-2.931583 4.5583878-6.23644742 7.3671111-6.23644742 7.3671111S6.79084963 24.1957884 3.885 19.8960468C2.14383169 17.3196664 0 14.4033686 0 11.1531099 0 4.99341738 4.43998041 0 9.91697368 0 15.393967 0 19.8339474 4.99341738 19.8339474 11.1531099c0 3.0534446-2.088085 6.2668121-3.6805263 8.7429369z" />
            <ellipse cx="10.0192105" cy="9.54210526" rx="6.13421053" ry="5.84210526" />
          </g>
        </g>
      </g>
    </svg>
  );
};
export default Format;
