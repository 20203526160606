import React from 'react';

const Video = ({ className = '' }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="75" height="75">
      <g fill="none" fillRule="evenodd" transform="translate(-1049 -1169)">
        <path fill="#F9FAFA" d="M-2 922h1440v1183H-2z"></path>
        <circle cx="1086.5" cy="1206.5" r="37.5" fill="#5C48D3" fillOpacity="0.095"></circle>
        <g
          stroke="#5C48D3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          transform="translate(1067 1194)"
        >
          <path d="M39 3.545L26.59 12.41 39 21.273z"></path>
          <rect width="26.591" height="24.818" rx="2"></rect>
        </g>
      </g>
    </svg>
  );
};
export default Video;
