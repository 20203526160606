import React from 'react';

const Calendar = ({ className = '' }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="75" height="75">
      <g fill="none" fillRule="evenodd">
        <circle cx="37.5" cy="37.5" r="37.5" fill="#5C48D3" fillOpacity="0.095"></circle>
        <path
          fill="#5C4DD0"
          fillRule="nonzero"
          d="M59 31.491L37.496 19 16 31.491l6.96 4.1v8.04a4.153 4.153 0 001.4 2.828 8.3 8.3 0 002.132 1.489v5.856a2.175 2.175 0 00-1.477 2.2 2.19 2.19 0 001.767 1.984c.145.016.29.016.435 0 1.059-.004 1.968-.739 2.175-1.757a2.186 2.186 0 00-1.424-2.51v-5.146a29.192 29.192 0 009.485 1.272c14.424 0 14.612-5.948 14.612-6.208l-.051-8.032L59 31.491zM27.942 55.996a.741.741 0 01-.853.578.731.731 0 01-.634-.792.738.738 0 01.78-.656h.144c.193.042.36.158.466.32.105.164.14.362.097.55zm9.554-35.323L56.09 31.5 37.496 42.426l-8.7-5.087 9.067-5.38a.725.725 0 00.358-.637.726.726 0 00-.384-.623.755.755 0 00-.742.014l-9.724 5.781-8.48-5.02 18.605-10.8zm-13.06 22.975V36.46l2.056 1.213v8.584c-1.962-1.247-2.047-2.585-2.056-2.61zm26.145 0c0 .041-.367 4.743-13.128 4.743-4.59 0-7.558-.602-9.485-1.388v-8.459l9.528 5.614 13.042-7.68.043 7.17z"
        ></path>
      </g>
    </svg>
  );
};
export default Calendar;
