import React from 'react';

const Discount = ({ className = '' }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="74"
      height="74"
      x="0"
      y="0"
      viewBox="0 0 38 38"
    >
      <circle r="19" cx="19" cy="19" fill="#eae9f6" shape="circle" />
      <g transform="matrix(0.42,0,0,0.42,11.020000000000003,11.020000000000007)">
        <g xmlns="http://www.w3.org/2000/svg">
          <path d="M0.293,36.293l36-36l1.414,1.414l-36,36L0.293,36.293z" fill="#5c48d3" />
          <path
            d="M6,12c3.309,0,6-2.691,6-6S9.309,0,6,0S0,2.691,0,6S2.691,12,6,12z M6,2c2.206,0,4,1.794,4,4s-1.794,4-4,4S2,8.206,2,6   S3.794,2,6,2z"
            fill="#5c48d3"
          />
          <path
            d="M32,26c-3.309,0-6,2.691-6,6s2.691,6,6,6s6-2.691,6-6S35.309,26,32,26z M32,36c-2.206,0-4-1.794-4-4s1.794-4,4-4   s4,1.794,4,4S34.206,36,32,36z"
            fill="#5c48d3"
          />
        </g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
        <g xmlns="http://www.w3.org/2000/svg"></g>
      </g>
    </svg>
  );
};
export default Discount;
